import { addPageBanner } from 'app/AppWrapper';
import { contextSrv } from 'app/core/core';

import { TopBanner } from './TopBanner';

export async function initAnnouncementBanners() {
  // TODO: Enable anonymous user visibility after backend support is enabled
  if (contextSrv.isSignedIn) {
    addPageBanner(TopBanner);
  }
}
